import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Form } from 'react-bootstrap/';

const goToDisplay = (url, greetingText) => {
    const baseUrl = `${window.location.origin}/display`;
    const queryString = `?eventsUrl=${encodeURIComponent(url)}&greeting=${encodeURIComponent(greetingText)}`;
    window.location.href = baseUrl + queryString;
};

const Dashboard = () => {
    const [error, setError] = useState(null);

    return (
        <div>
            <Card>
                <Form onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submit action
                    const apiUrl = e.target.elements['apiUrl'].value; // Access the API URL input value
                    const greetingText = e.target.elements['greetingText'].value; // Access the Greeting Text input value
                    goToDisplay(apiUrl, greetingText); // Call the goToDisplay function with the input values
                }}>
                    <Form.Group className="mb-3" controlId="apiUrl">
                        <Form.Label>Api URL</Form.Label>
                        <Form.Control type="text" placeholder="https://schedulr.example.com/api/v1/events" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="greetingText">
                        <Form.Label>Greeting Text</Form.Label>
                        <Form.Control type="text" placeholder="WELCOME TO OUR FACILITY!" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="submit" value="Submit" />
                    </Form.Group>
                </Form>
            </Card>
        </div>
    );
};

export default Dashboard;
