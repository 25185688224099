import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginView from './login.js';
import DashboardView from './Dashboard.js';
import DisplayView from './Display';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation, // Import useLocation
} from "react-router-dom";

// Helper function to parse query parameters
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function App() {
    return (
      <div>
        <Router>
            <Switch>
                <Route exact path="/">
                    <Dashboard />
                </Route>

                <Route exact path="/login">
                    <Login />
                </Route>

                <Route exact path="/register"> {/* Make sure this path starts with a slash */}
                    <Register />
                </Route>

                <Route path="/display">
                    <Display />
                </Route>
            </Switch>
        </Router>
      </div>
    )
}

function Dashboard() {
  return (
    <div>
      <DashboardView />
    </div>
  )
}

function Login() {
  return (
    <div>
      <LoginView />
    </div>
  )
}

function Register() {
  return (
    <div>
      <h1>Register</h1>
    </div>
  )
}

function Display() {
    let query = useQuery();
    let eventsUrl = query.get("eventsUrl");
    let greeting = query.get("greeting");
    let background = query.get("dataURL")


    return (
        <DisplayView eventUrl={eventsUrl} greetingText={greeting} background={background}/>
    )
}
