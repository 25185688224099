import React, { useState, useEffect } from 'react';
import './App.css';
import './Display.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Col } from 'react-bootstrap/';
import Event from './Event.js';

// Accept props in the Display component
function Display(props) {
    const [today, setToday] = useState(new Date().toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));

    useEffect(() => {
        const dateSync = () => {
            setToday(new Date().toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));
            console.log('Date refreshed:', new Date());
            window.location.reload(); // Refresh the entire page
        };

        const interval = setInterval(dateSync, 60000); // Refresh every 1 minute (60000 milliseconds)

        return () => clearInterval(interval); // Clean up the interval on unmount
    }, []);

    // Style object for the background image
    const backgroundStyle = {
        backgroundImage: `url('${props.background}')`,
        backgroundSize: 'cover', // Cover the entire area of the component
        backgroundPosition: 'center', // Center the background image
    };

    return (
        <div key="events" style={backgroundStyle}> {/* Apply the backgroundStyle here */}
            <Navbar bg="" className="transparentBypass displayNav">
                <Col md={4}>
                    <Navbar.Brand href="#" className="d-none d-lg-block d-xl-block" style={{fontSize: '3.5em', fontWeight: '900', fontFamily: 'lato', color: 'white'}}>{props.greetingText}</Navbar.Brand>
                    <Navbar.Brand href="#" className="d-block d-sm-block d-xs-block d-md-none d-lg-none text-center" style={{fontWeight: 'bold', fontFamily: 'lato', color: 'white'}} >Welcome to TeamWorking</Navbar.Brand>
                    <Navbar.Brand style={{fontSize: '2.5em', color: 'white'}}>{today}</Navbar.Brand>
                </Col>
                <Col md={{span: 5, offset: 3}} className="d-none d-lg-block d-xl-block text-right">
                </Col>
            </Navbar> 

            <div className="cardsUnder">
                {/* Use the eventUrl prop to render the Event component */}
                <Event eventUrl={props.eventUrl} />
            </div>                 
        </div>
    );
}

export default Display;
