import React, { useState, useEffect, useRef } from 'react';
import './Event.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Spinner, Pagination } from 'react-bootstrap/';

const Event = ({ eventUrl }) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [eventPerPage, setEventPerPage] = useState(5);
    const [totalEvents, setTotalEvents] = useState(0);
    const intervalIDRef = useRef(null);

    const getEvents = () => {
        console.log('Fetching events @ ', Date());
        fetch(eventUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('Events refreshed @ ', Date());
                    setIsLoaded(true);
                    setEvents(result);
                    setTotalEvents(result.length);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    };

    const loopPages = () => {
        console.log('Invoking loopPages function');
        let totalPages = Math.ceil(totalEvents / eventPerPage);

        setCurrentPage((prevPage) => {
            let nextPage = prevPage + 1;
            if (nextPage > totalPages) {
                return 1;
            }
            return nextPage;
        });

        intervalIDRef.current = setTimeout(loopPages, 7000);
    };

    useEffect(() => {
        getEvents();
        const fetchInterval = setInterval(getEvents, 60000); // Fetch events every minute
        intervalIDRef.current = setTimeout(loopPages, 7000); // Start loopPages after first render

        return () => {
            clearInterval(fetchInterval);
            clearTimeout(intervalIDRef.current);
        };
    }, [totalEvents]);

    if (!isLoaded) {
        return <div className="text-center">
                    <Spinner animation="grow" variant="info" />
                    <p>Loading...</p>
                </div>;
    }

    if (error) {
        return <div>
                <Card className='cards' key={'errorEvent'}>
                    <Card.Body>
                        <Card.Title className={'event-title'}>{error.message}</Card.Title>
                    </Card.Body>
                </Card>
            </div>;
    } else {
        const indexOfLastEvent = currentPage * eventPerPage;
        const indexOfFirstEvent = indexOfLastEvent - eventPerPage;
        const currentEvent = events.slice(indexOfFirstEvent, indexOfLastEvent);
        const pageNumbers = [];
        for (let x = 1; x <= Math.ceil(events.length / eventPerPage); x++) {
            pageNumbers.push(
                <Pagination.Item key={x} active={x === currentPage} onClick={() => setCurrentPage(x)}>
                    {x}
                </Pagination.Item>
            );
        }

        return (
            <div className="Display">
                {currentEvent.map((event, index) => (
                    <Card className='cards' key={index}>
                        <Card.Body>
                            <Card.Title className={'event-title'}>{event.summary}</Card.Title>
                            <Card.Text className={'event-location'}>{event.location}</Card.Text>
                            <Card.Text className={'event-time'}>
                                {new Date(event.starts).toLocaleTimeString('en-US', { timeStyle: 'short' })} - 
                                {new Date(event.ends).toLocaleTimeString('en-US', { timeStyle: 'short' })}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                ))}
                {/* <Pagination>{pageNumbers}</Pagination> */}
            </div>
        );
    }
};

export default Event;
