import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card, Form, Button } from 'react-bootstrap/';

class LoginView extends React.Component {
    render() {
        return(
            <Container type='fluid'>
                <Col>
                    <Row>
                        <Col xs={{span: 6, offset: 3}}>
                            <Card style={{marginTop: '15%', backgroundColor: 'white'}}>    
                                <Card.Body>
                                    <Card.Title>Login</Card.Title>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Email:</Form.Label>
                                            <Form.Control type='email' placeholder='username@yourcompany.com' />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Password:</Form.Label>
                                            <Form.Control type='Password' placeholder='Ar34l1yh4rdp45w0rd' />
                                        </Form.Group>

                                        <Button variant ="success" type="submit">
                                        Login
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Container>
        )
    }

}

export default LoginView;